// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import Container from '@mui/material/Container';
import Iconify from 'src/components/iconify';
import { useLocales } from 'src/locales';

import Typography from '@mui/material/Typography';
// routes

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Footer() {
  const { t } = useLocales();

  const simpleFooter = (
    <Box
      component="footer"
      sx={{
        py: 5,
        textAlign: 'center',
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Container>
        <Link
          variant="subtitle2"
          href={`https://api.whatsapp.com/send?text=${window.location.href}`}
          data-action="share/whatsapp/share"
          target="_blank"
          rel="noopener"
          sx={{
            color: 'text.secondary',
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          <Iconify icon="ic:baseline-whatsapp" width={16} sx={{ mr: 1 }} />
          {t('share')}
        </Link>
        <Typography variant="caption" component="div">
          © All rights reserved
          <br /> made by
          <Link href="https://unisot.com/"> UNISOT AS </Link>
        </Typography>
      </Container>
    </Box>
  );

  return simpleFooter;
}
